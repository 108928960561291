<template>
  <v-container>
    <v-container>
      <v-row justify="center">
          商品一覧
      </v-row>
      <v-row justify="center">
        <v-data-table dense multi-sort :headers="headers" :items="items" :options="options">
          <template v-slot:item.color="{ item: {color} }">
            {{!color ? "-" : color}}
          </template>
          <template v-slot:item.size="{ item: {size} }">
            {{!size ? "-" : size}}
          </template>
          <template v-slot:item.itemTopImagePath="{ item: {itemTopImagePath} }">
            <v-img :src="itemTopImagePath" max-height="80" max-width="80" />
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import firebaseStorageUtil from '../utils/firebase_storage_util'
  import Endpoints from '../consts/api_endpoint_map.js'

  export default {
    name: "get-item-list",
    data () {
      return {
        headers: [
          {text: "商品画像", value: "itemTopImagePath"},
          {text: "商品名", value: "uniqueItemName"},
          {text: "サイズ", value: "size"},
          {text: "色", value: "color"},
          {text: "販売価格", value: "sellingPrice"},
          {text: "在庫数", value: "stockNum"},
        ],
        items: [],
        options: {
          itemsPerPage: 30
        },
      }
    },
    async created () {
      const items = await this.search()
      this.items = items.filter(i => i.sellFlg === "1")
      // imageUrlの取得が、 v-data-table の slot では対応できない（awaitできない）ため、こちらで変換する
      console.log(firebaseStorageUtil)
      for(var item of items){
        item.itemTopImagePath = await firebaseStorageUtil.getImgUrl(item.itemTopImagePath) 
        console.log(item.itemTopImagePath)
      }
    },
    methods: {
      async search(){
        const res = await axios.get(Endpoints.getItemListEndpoint)
        if(res.status === 200){
          return res.data.responseBody.itemList
        } else {
          console.log(`error : res.status is ${res.status}`)
          console.log(res)
          return []
        }
      },
    }
  }
</script>

<style>
</style>
